<script setup lang="ts">
import Fieldset from '@/components/fieldset/Fieldset.vue';
import StepFooter from '@/components/stepContainer/StepFooter.vue';
import StepContainer from '@/components/stepContainer/StepContainer.vue';
import ReportFiltersForm from '@/domains/reports/components/form/ReportFiltersForm.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import { formatNumber } from '@/utils/numbers';
import { AppliedFilters } from '@/components/distiller/types';
import CreateCustomReport from './CreateCustomReport.vue';
import GlobalFiltersForm from '@/domains/reports/components/form/GlobalFiltersForm.vue';

const props = defineProps<{
  customReport: App.Reports.Data.CustomReportData;
  globalDistiller: App.Search.Data.DistillerData;
  filters?: AppliedFilters;
  columns?: App.Reports.Data.ColumnData[];
  columnFilters: AppliedFilters;
  reportTemplate?: App.Reports.Data.ReportTemplateData;
  shouldShowGlobalFilters: boolean;
}>();

const isConfirmationDialogOpen = ref<boolean>(false);
const searchRecordCount = ref<App.Search.Data.SearchRecordCountData>({ count: 0 });

const nextForm = useForm({
  url: route('custom-reports.columns.create', { customReport: props.customReport.id }),
  only: ['columns', 'filters', 'column_filters'],
  key: `custom-reports.filters.next.${props.customReport.id}`,
  fields: {
    filters: props.filters ?? props.customReport.default_filters ?? {},
    column_filters: props.columnFilters ?? {},
    columns: props.columns ?? [],
    report_template_id: props.reportTemplate?.id ?? null,
    validate_record_count: true
  }
});

async function handleNext(validate_record_count: boolean = true): Promise<void> {
  nextForm.fields.validate_record_count = validate_record_count;
  await nextForm.submit();
  if (nextForm.errors['record_count'] && Object.keys(nextForm.errors).length === 1) {
    isConfirmationDialogOpen.value = true;
    searchRecordCount.value.count = parseInt(nextForm.errors['record_count']);
  }
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CreateCustomReport :currentStep="0" :customReport="customReport">
    <StepContainer title="Select filters">
      <div class="mx-auto w-full max-w-4xl space-y-5">
        <div class="space-y-10">
          <Fieldset
            title="Report record filters"
            :description="`Use these filters to control which ${customReport.distiller?.name?.label} records will be included in the output of this report.`"
          >
            <ReportFiltersForm :customReport="customReport" :form="nextForm" />
          </Fieldset>
          <GlobalFiltersForm
            v-if="shouldShowGlobalFilters"
            :form="nextForm"
            :globalDistiller="globalDistiller"
          />
          <StepFooter
            :showPreviousButton="false"
            :isLoading="nextForm.processing"
            @previous="router.get(route('custom-reports.types.index'))"
            @next="handleNext"
          />
        </div>
      </div>
    </StepContainer>

    <DangerDialog
      :title="searchRecordCount.count === 0 ? 'Empty Report Results' : 'Large Report Result'"
      :message="`The filters you have selected will generate a report with ${formatNumber(
        searchRecordCount.count
      )} results. Are you sure you want to proceed with this report?`"
      :isOpen="isConfirmationDialogOpen"
      :isLoading="nextForm.processing"
      confirmButtonLabel="Proceed with report"
      @onCancel="isConfirmationDialogOpen = false"
      @onClose="isConfirmationDialogOpen = false"
      @onConfirm="handleNext(false)"
    />
  </CreateCustomReport>
</template>
