<script setup lang="ts">
import { toRaw } from 'vue';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useZindex } from '@/hooks/useZindex';

import Button from '@/components/button/Button.vue';
import IconButton from '@/components/button/IconButton.vue';

import Times from '@/icons/line/times.svg';

export type DialogProps = {
  cancelButtonLabel?: string | null;
  confirmButtonDisabled?: boolean;
  confirmButtonLabel?: string;
  confirmButtonColor?: ButtonColor | keyof typeof ButtonColor;
  contentClass?: string;
  description?: string;
  formId?: string;
  footerHelperText?: string;
  icon?: Component;
  isLoading?: boolean;
  isOpen?: boolean;
  message?: string;
  step?: number;
  steps?: number;
  title: string;
  variant?: 'default' | 'danger' | 'warning';
};

const props = withDefaults(defineProps<DialogProps>(), {
  cancelButtonLabel: 'Cancel',
  confirmButtonLabel: 'Save changes',
  isOpen: false,
  variant: 'default',
  contentClass: 'md:max-w-[475px]'
});

const emit = defineEmits<{
  onCancel: [void];
  onClose: [void];
  onConfirm: [void];
}>();

const rawIcon = toRaw(props.icon);

const { nextIndex } = useZindex();
const zIndex = nextIndex();

const computedProgressWidth = computed(() => {
  if (!props.step || !props.steps) {
    return '0%';
  }
  return `${(props.step / props.steps) * 100}%`;
});

const computedButtonColor = computed(() => {
  const colorMap: Record<string, 'danger' | 'primary' | 'slate'> = {
    default: 'primary',
    danger: 'danger',
    warning: 'slate'
  };
  return props.confirmButtonColor || colorMap[props.variant] || undefined;
});

function onCancelButtonClick() {
  emit('onCancel');
}

function onOverlayClick() {
  emit('onClose');
}

function onCloseButtonClick() {
  emit('onClose');
}

function onSubmit() {
  emit('onConfirm');
}
</script>

<template>
  <teleport to="body">
    <Transition
      ariaLive="assertive"
      enterActiveClass="transitionSlideUpAndFade duration-200"
      leaveActiveClass="transition-slide-up-and-fade duration-200 absolute inset-x-0 top-0"
      enterFromClass="opacity-50"
      leaveToClass="opacity-0"
      class="fixed"
      :style="{ zIndex }"
    >
      <form v-if="isOpen" @submit.prevent="onSubmit" class="md:px-4">
        <div
          class="fixed inset-0 bg-black/70 data-[state=open]:animate-overlay-show"
          @click="onOverlayClick"
          :style="{ zIndex }"
        />

        <div
          class="pointer-events-none fixed bottom-0 left-0 right-0 flex w-full flex-col items-center md:bottom-auto md:left-[50%] md:right-auto md:top-[50%] md:translate-x-[-50%] md:translate-y-[-50%] md:px-4"
          :style="{ zIndex }"
        >
          <div
            class="pointer-events-auto flex max-h-[85vh] w-full flex-col rounded-t-xl bg-white shadow-xl focus:outline-none data-[state=open]:animate-content-show md:rounded-lg md:border md:border-slate-200"
            :class="contentClass"
          >
            <div
              class="relative flex items-center justify-between gap-x-4 border-b border-slate-200 p-5 pr-4 md:pb-4"
            >
              <div class="flex grow flex-col gap-y-0.5 md:gap-y-1">
                <slot name="header">
                  <div class="text-base font-semibold leading-5 text-slate-900">
                    {{ title }}
                  </div>
                  <div v-if="description" class="text-sm leading-4 text-slate-500 md:leading-5">
                    {{ description }}
                  </div>
                </slot>
              </div>
              <IconButton
                ariaLabel="Close dialog"
                :icon="Times"
                :size="ButtonSize.md"
                :variant="ButtonVariant.invisible"
                @click="onCloseButtonClick"
              />
              <div
                v-if="steps"
                class="absolute inset-x-0 -bottom-px flex h-0.5 w-full items-stretch bg-slate-200"
              >
                <div class="bg-primary-700" :style="{ width: computedProgressWidth }" />
              </div>
            </div>
            <div class="flex flex-col gap-y-4 overflow-auto px-5 py-6">
              <div class="flex items-start gap-2">
                <div
                  v-if="rawIcon"
                  class="rounded-full p-1"
                  :class="{
                    'bg-slate-100': variant === 'default',
                    'bg-red-100': variant === 'danger',
                    'bg-amber-100': variant === 'warning'
                  }"
                >
                  <component
                    :is="rawIcon"
                    class="h-4.5 w-4.5"
                    :class="{
                      'text-slate-500': variant === 'default',
                      'text-red-500': variant === 'danger',
                      'text-amber-700': variant === 'warning'
                    }"
                  />
                </div>
                <slot>
                  <div class="whitespace-pre-line text-sm leading-5 text-slate-500">
                    <slot name="message">{{ message }}</slot>
                  </div>
                </slot>
              </div>
              <div v-if="$slots.body">
                <slot name="body" />
              </div>
            </div>
            <div
              class="flex w-full flex-col justify-stretch gap-x-6 gap-y-4 border-t border-slate-200 px-5 py-2.5 md:flex-row md:flex-wrap md:items-center md:justify-between"
            >
              <slot v-if="footerHelperText" name="footerHelperText">
                <p class="min-w-48 flex-1 text-sm font-semibold text-slate-700">
                  {{ footerHelperText }}
                </p>
              </slot>
              <div
                class="flex w-full flex-wrap-reverse gap-x-2.5 gap-y-3 md:ml-auto md:w-auto md:justify-end md:gap-x-2 md:gap-y-2"
              >
                <slot name="footer">
                  <Button
                    v-if="cancelButtonLabel"
                    class="flex-1 md:hidden md:flex-none"
                    :color="ButtonColor.slate"
                    :size="ButtonSize.lg"
                    :variant="ButtonVariant.outlined"
                    @click="onCancelButtonClick"
                  >
                    {{ cancelButtonLabel }}
                  </Button>
                  <Button
                    v-if="confirmButtonLabel"
                    class="flex-1 md:hidden md:flex-none"
                    :color="computedButtonColor"
                    :isDisabled="isLoading || confirmButtonDisabled"
                    :isLoading="isLoading"
                    :size="ButtonSize.lg"
                    :variant="ButtonVariant.solid"
                    type="submit"
                  >
                    {{ confirmButtonLabel }}
                  </Button>
                  <Button
                    v-if="cancelButtonLabel"
                    class="hidden flex-1 md:inline-flex md:flex-none"
                    :color="ButtonColor.slate"
                    :size="ButtonSize.sm"
                    :variant="ButtonVariant.outlined"
                    @click="onCancelButtonClick"
                  >
                    {{ cancelButtonLabel }}
                  </Button>
                  <Button
                    v-if="confirmButtonLabel"
                    class="hidden flex-1 md:inline-flex md:flex-none"
                    :color="computedButtonColor"
                    :isDisabled="isLoading || confirmButtonDisabled"
                    :isLoading="isLoading"
                    :size="ButtonSize.sm"
                    :variant="ButtonVariant.solid"
                    type="submit"
                  >
                    {{ confirmButtonLabel }}
                  </Button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Transition>
  </teleport>
</template>
